import axios from 'axios';

export const getRoles = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/roles/GetRoles');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}




import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React from 'react';
import { PaginaInicial } from "./Paginas/PaginaInicial";
import AcercaDe from "./Paginas/AcercaDe";
import VideoPage from "./Paginas/VIdeoPage";
import DocumentPage from "./Paginas/DocumentPage";
import WorkshopPage from "./Paginas/WorkshopPage";
import LoginPage from "./Paginas/LoginPage";
import MenuPage from "./Paginas/MenuPage";
import MenuTutorPage from "./Paginas/MenuTutorPage";
import MantenimientoTallerPage from "./Paginas/MantenimientoTallerPage";
import MantenimientoTutoriasPage from "./Paginas/MantenimientoTutoriasPage";
import MantenimientoPersonaPage from "./Paginas/MantenimientoPersonaPage";
import MantenimientoRolPage from "./Paginas/MantenimientoRolPage";
import MantenimientoUsuarioPage from "./Paginas/MantenimientoUsuarioPage"
import MantenimientoParametroPage from "./Paginas/MantenimientoParametroPage";
import MantenimientoTutorPage from "./Paginas/MantenimientoTutorPage";
import MantenimientoVideoPage from "./Paginas/MantenimientoVideoPage";
import MantenimientoDocumentoPage from "./Paginas/MantenimientoDocumentoPage";
import MantenimientoFotoPage from "./Paginas/MantenimientoFotoPage";
import Table from "./Componentes/Table/Table";
import Modal from "./Componentes/Modal/Modal";
import CalendarPage from "./Paginas/CalendarPage";
import ChangePasswordPage from "./Paginas/ChangePasswordPage";

export function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<PaginaInicial />} />
          <Route path="/AcercaDe" element={<AcercaDe />} />
          <Route path="/VideoPage" element={<VideoPage />} />
          <Route path="/DocumentPage" element={<DocumentPage />} />
          <Route path="/WorkshopPage" element={<WorkshopPage />} />
          <Route path="/LoginPage" element={<LoginPage />} />

          <Route path="/MenuPage" element={<MenuPage />} />
          <Route path="/MenuTutorPage" element={<MenuTutorPage />} />
          <Route path="/MantenimientoTallerPage" element={<MantenimientoTallerPage />} />
          <Route path="/MantenimientoTutoriasPage" element={<MantenimientoTutoriasPage />} />
          <Route path="/MantenimientoPersonaPage" element={<MantenimientoPersonaPage />} />
          <Route path="/MantenimientoRolPage" element={<MantenimientoRolPage />} />
          <Route path="/MantenimientoUsuarioPage" element={<MantenimientoUsuarioPage />} />
          <Route path="/MantenimientoParametroPage" element={<MantenimientoParametroPage />} />
          <Route path="/MantenimientoTutorPage" element={<MantenimientoTutorPage />} />
          <Route path="/MantenimientoVideoPage" element={<MantenimientoVideoPage />} />
          <Route path="/MantenimientoDocumentoPage" element={<MantenimientoDocumentoPage />} />
          <Route path="/MantenimientoFotoPage" element={<MantenimientoFotoPage />} />

          <Route path="/Table" element={<Table />} />
          <Route path="/Modal" element={<Modal />} />
          <Route path="/CalendarPage" element={<CalendarPage />} />
          <Route path="/ChangePasswordPage" element={<ChangePasswordPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />

        </Routes>
    </BrowserRouter>
  );
}


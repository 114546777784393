import React, {useEffect} from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import Navbar from '../Componentes/Navbar/Navbar';
import MenuTutor from '../Componentes/Menu/MenuTutor';

const MenuTutorPage = () => {

  const navegar = useNavigate();
  const cookies = new Cookies();

  

  useEffect(() => {
    if(!cookies.get('token')){
      navegar("/");
    }
  }, []);
  
  return (
    <div>
    <Navbar></Navbar>
    
      <MenuTutor />
    </div>
  );
};

export default MenuTutorPage;

import React, { useState } from 'react';
import axios from 'axios';
import Cookies from "universal-cookie";
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import imgLogin from '../../Recursos/estudiando.png';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import { AuthProvider, useAuth } from "../AuthContext";


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const { login } = useAuth();
    const { logout } = useAuth();
    const [password, setPassword] = useState('');
    const [showPassword] = useState(false); 


    
    const Login = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('https://www.srb-unainvestigacion.org:3000/user/login', {
                email,
                password
            });
            if (res.data.status === 'error') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Usuario o contraseña incorrectos!',
                })
                return alert(res.data.error);
            }
            else {
                login(res.data.token);
                if (jwtDecode(res.data.token).rol_Nombre === 'Admin') {
                    navigate('/MenuPage');
                }
                else {
                    navigate('/MenuTutorPage');
                }
            }
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o contraseña incorrectos!',
            })
            console.log(error);
        }
    }



    return (
        <div className="login-page">
            <div className="login-card">
                <div className="login-left">
                    <img
                        src={imgLogin}
                        alt="Imagen"
                        className="login-image"
                    />
                </div>
                <div className="login-right">
                    <div className="login-logo">
                        <h2>Iniciar Sesión</h2>
                    </div>
                    <form>
                        <label htmlFor="email">Correo:</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="ejemplo@est.una.ac.cr" />

                        <label htmlFor="password">Contraseña:</label>
                        <div className="password-input-wrapper">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña_UNA" 

                            />
            
                        </div>

                        <button type='button' className='button_loggin' onClick={Login}>Iniciar Sesión</button>
                    </form>
                    <div className="login-signup-link">
                        ¿Olvidaste tu contraseña? <Link to="/ChangePasswordPage">Cambia tu contraseña</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
import React, { useEffect, useState } from "react";
import "./MenuCalendar.css";
import { getAllTutorias } from "../../Service/TutoriasService";
import Card from "../Card/Card";
import Calendar from "./Calendar";

const MenuCalendar = () => {
    const [opcionesMenu, setOpcionesMenu] = useState([]);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [idTutor, setIdTutor] = useState(null);
    const [tutoria, setTutoria] = useState(null);
    const [correoTutor, setCorreoTutor] = useState(null);
    const [duracion, setDuracion] = useState(null);
    const [idTutoria, setidtutoria] = useState(null);

    const sections = [
        {
            title: "¿Cómo agendar una tutoría?",
            content: "Para agendar una tutoría, selecciona la tutoría de tu interés, elige el día y la hora disponibles, y completa los campos de nombre y correo. Un correo se enviará automáticamente con la información de la solicitud, y será atendido por el tutor correspondiente.",
        },
        {
            title: "¿Qué tipos de tutorías ofrecemos?",
            content: "Ofrecemos una variedad de tutorías que cubren diferentes áreas de estudio, desde matemáticas y ciencias hasta idiomas y desarrollo personal. Cada tutoría es impartida por expertos en la materia, asegurando que recibas la mejor orientación posible.",
        },
        {
            title: "Beneficios de usar nuestro servicio",
            content: "Nuestro servicio de tutorías está diseñado para ser flexible y accesible. Puedes agendar sesiones en cualquier momento, desde cualquier lugar, y adaptarlas a tu ritmo de aprendizaje. Además, nuestro sistema de seguimiento te permite revisar tu progreso y ajustar tus sesiones según tus necesidades.",
        },
        {
            title: "Preguntas frecuentes",
            content: "¿Puedo cancelar una tutoría? Sí, puedes cancelar una tutoría hasta 24 horas antes de la sesión programada sin costo alguno. ¿Qué sucede si un tutor no se presenta? En caso de que un tutor no se presente, podrás reprogramar la sesión o solicitar un reembolso completo.",
        },
        {
            title: "Contacto",
            content: "Si tienes alguna duda o necesitas asistencia, no dudes en ponerte en contacto con nosotros. Puedes enviarnos un correo a soporte@tutoria.com o llamarnos al (555) 123-4567. Nuestro equipo de soporte está disponible de lunes a viernes, de 9:00 a.m. a 6:00 p.m.",
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const tutorias = await getAllTutorias();
           
            const tutoriasCards = tutorias.map((tutoria) => {
                return {
                    ...tutoria,
                    onClick: () => {
                        setOpenCalendar(true);
                        setIdTutor(tutoria.Per_Id);
                        setTutoria(tutoria.nombre);
                        setCorreoTutor(tutoria.correo);
                        setDuracion(tutoria.duracion);
                        setidtutoria(tutoria.Id);
                    }
                };
            });
            setOpcionesMenu(tutoriasCards);
        };
        fetchData();
    }, []);

    const handleCloseModal = () => {
        setOpenCalendar(false);
    };

    return (
        <div className="menu-calendar-container">
            <h1 className="menu-title">Información General sobre Tutorías</h1>
            
            {/* Sección de información general */}
            <div className="info-sections">
                {sections.map((section, index) => (
                    <div key={index} className="info-section">
                        <h2 className="section-title">{section.title}</h2>
                        <p className="section-content">{section.content}</p>
                    </div>
                ))}
            </div>

            {/* Sección de selección de tutorías */}
            <h2 className="menu-title">Seleccione una Tutoría</h2>
            <div className="menu-page">
                {opcionesMenu.length > 0 ? (
                    opcionesMenu.map((opcion, index) => (
                        <Card key={index} {...opcion} />
                    ))
                ) : (
                    <div className="loading">Cargando...</div>
                )}
            </div>
            {openCalendar && (
                <Calendar
                    idTutoria={idTutoria}
                    idTutor={idTutor}
                    isOpen={openCalendar}
                    onClose={handleCloseModal}
                    tutoria={tutoria}
                    correoTutor={correoTutor}
                    duracion={duracion}
                />
            )}
        </div>
    );
};

export default MenuCalendar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WorkshopStyle.css';
import Card from '../Card/Card';


const Workshop = () => {
  const [talleres, setTalleres] = useState([]);

  useEffect(() => {

    axios.get('https://www.srb-unainvestigacion.org:3000/taller/GetTaller')
      .then(response => {
        setTalleres(response.data);
      })
      .catch(error => {
        console.error('Error obteniendo la lista de talleres:', error);
      });
  }, []);

  if (talleres.length === 0) {
    return <div>Cargando...</div>;
  }



  const handleEnviarSolicitud = () => {
    const mensaje = encodeURIComponent(`
      Estimado Centro de Escritura,

      Espero que este mensaje le encuentre bien. Mi nombre es [Tu Nombre], y estoy interesado/a en participar en uno o varios de los talleres que ofrece su organización.

      He tenido la oportunidad de revisar la información disponible en su página web y estoy impresionado/a por la variedad y calidad de los talleres que ofrecen. Me gustaría obtener más detalles sobre los siguientes talleres:

      1. Taller 1: [Nombre del Taller]
      2. Taller 2: [Nombre del Taller]
      3. Taller 3: [Nombre del Taller]

      Agradecería mucho si pudiera proporcionarme información adicional sobre los temas, fechas, horarios y costos asociados a cada taller. Además, si hay algún requisito previo para la participación, agradecería que también se incluyera en la información.

      Quedo a la espera de su respuesta y agradezco de antemano su atención a esta solicitud. Estoy emocionado/a por la posibilidad de participar en sus talleres y enriquecer mi conocimiento en [tema o área específica].

      Agradezco su tiempo y atención.

      Atentamente,

      [Tu Nombre]
      [Correo Electrónico]
      [Teléfono de Contacto]
  `);
    window.location.href = `mailto:centrodeescriturasrb@una.cr?subject=Solicitud de Información sobre Talleres&body=${mensaje}`; 
  };

  return (
    <div className="talleres-page">
      <div className="talleres-grid">
        {talleres.map(taller => (
          <Card
            key={taller.Tal_Id}
            nombre={taller.Tal_Nombre}
            descripcion={taller.Tal_Descripcion}
          />
        ))}
      </div>
      <div className="solicitar-btn">
        <button onClick={handleEnviarSolicitud}>Solicitar Taller</button>
      </div>


    </div>
  );
};

export default Workshop;




import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children, navigate }) => {
  const cookies = new Cookies();
  const initialToken = cookies.get('token') || null;
  const [token, setToken] = useState(initialToken);
  const [islogin, setIslogin] = useState(false);
  const timerRef = useRef(null);
  const userActivityRef = useRef(false);
  const login = (newToken) => {
    setToken(newToken);
    cookies.set('token', newToken, { path: '/' });
    clearTimeout(timerRef.current);
    startCountdown();
  };

  const logout = () => {
    clearTimeout(timerRef.current);
  };

  const startCountdown = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => countdown(900), 1000);
  };

  const countdown = (seconds) => {
    let remainingTime = seconds;

    const updateTimer = () => {
      if (remainingTime <= 0) {
        setToken(null);
        cookies.remove('token');
        setIslogin(false);
        Swal.fire({
          icon: 'error',
          title: 'Sesión expirada',
          text: 'La sesión ha expirado, por favor inicia sesión de nuevo.',
        }).then(() => {
          window.location.reload(); // Recarga la página cuando la sesión expire
        });
      } else {
        if (!userActivityRef.current) {
          remainingTime--;
        } else {
          remainingTime = 900; 
          userActivityRef.current = false;
        }
        setTimeout(updateTimer, 1000);
      }
    };
    updateTimer();
  };
  const handleUserActivity = () => {
    userActivityRef.current = true; // Hay actividad del usuario
  };

  window.addEventListener('mousemove', handleUserActivity);
  window.addEventListener('keydown', handleUserActivity);
  return (
    <AuthContext.Provider value={{ token, login, logout,islogin }}>
      {children}
    </AuthContext.Provider>
  );
};
import React from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import MenuCalendar from '../Componentes/Calendar/MenuCalendar';

const CalendarPage = () => {
    
    return (
        <div>
        <Navbar></Navbar>
        
        <MenuCalendar/>
        </div>
    );
    };

export default CalendarPage;
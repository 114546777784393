import React from 'react';
import PropTypes from 'prop-types';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from 'react-router-dom';
import './Footer.css'; // Importa la hoja de estilos CSS

const Footer = ({ parametros }) => {
  const correoInfo = parametros.find(item => item.Par_Nombre === 'Correo');
  const telefonoInfo = parametros.find(item => item.Par_Nombre === 'Telefono');
  const direccionInfo = parametros.find(item => item.Par_Nombre === 'Direccion');
  const descripcionInfo = parametros.find(item => item.Par_Nombre === 'Descripcion');

  return (
    <MDBFooter className='mdb-footer text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Contactenos en las redes sociales:</span>
        </div>

        <div className='social-media'>
          <Link to='https://www.facebook.com/UNACampusPZ' className='me-4 text-reset'>
            <MDBIcon fab icon='facebook-f' className='icon-facebook' />
          </Link>
          <Link to='https://www.srb.una.ac.cr/index.php/es/' className='me-4 text-reset'>
            <MDBIcon fab icon='google' className='icon-google' />
          </Link>
          <Link to='https://www.instagram.com/una.ac.cr?igsh=MXFzejUwdnhlcnRiMg==' className='me-4 text-reset'>
            <MDBIcon fab icon='instagram' className='icon-instagram' />
          </Link>
          <Link to='https://www.linkedin.com/school/universidad-nacional/' className='me-4 text-reset'>
            <MDBIcon fab icon='linkedin' className='icon-linkedin' />
          </Link>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon='gem' className='me-3' />
                Centro de Escritura
              </h6>
              <p>
                {descripcionInfo?.Par_Descripcion}
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Servicios</h6>
              <p>
                <Link to='/WorkshopPage' className='text-reset'>
                  Talleres
                </Link>
              </p>
              <p>
                <Link to='/CalendarPage' className='text-reset'>
                  Tutorías
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Recursos</h6>
              <p>
                <Link to='/VideoPage' className='text-reset'>
                  Videos
                </Link>
              </p>
              <p>
                <Link to='/DocumentPage' className='text-reset'>
                  Documentos
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contactos</h6>
              <p>
                <MDBIcon icon='home' className='me-2 icon' />
                {direccionInfo?.Par_Descripcion}
              </p>
              <p>
                <MDBIcon icon='envelope' className='me-3 icon' />
                {correoInfo?.Par_Descripcion}
              </p>
              <p>
                <MDBIcon icon='phone' className='me-3 icon' /> 
                {telefonoInfo?.Par_Descripcion}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4 copyright'>
        © 2024 Copyright:
        <Link className='text-reset fw-bold' to='https://www.srb.una.ac.cr/index.php/es/'>
          Todos los derechos reservados Universidad Nacional Sede Regional Brunca
        </Link>
      </div>
    </MDBFooter>
  );
}

Footer.propTypes = {
  parametros: PropTypes.array.isRequired,
};

export default Footer;

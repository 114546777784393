import React, { useEffect, useState } from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import Footer from "../Componentes/Footer/Footer";
import { getParametros } from '../Service/ParametrosService';
import './styles/AcercaDe.css';

const AcercaDe = () => {
  const [parametros, setParametros] = useState([]);

  useEffect(() => {
      getParametros().then((response) => {
          setParametros(response);
      });
  }, []);

  if (parametros.length === 0) {
      return (
          <div className="loading-container">
              <div className="spinner"></div>
              <p>Cargando...</p>
          </div>
      );
  }

  const mision = parametros.find(item => item.Par_Nombre === 'Misión');
  const vision = parametros.find(item => item.Par_Nombre === 'Visión');
  const queSomos = parametros.find(item => item.Par_Nombre === 'Bienvenida');

  return (
      <div>
          <Navbar />
          <section className="acercaDeStyles">
              <div className="content-container">
                  <h2 id="Mision" className="fade-in">Misión</h2>
                  <p className="fade-in">{mision.Par_Descripcion}</p>
                  <h2 id="Vision" className="fade-in">Visión</h2>
                  <p className="fade-in">{vision.Par_Descripcion}</p>
                  <h2 id="Junta" className="fade-in">¿Qué somos?</h2>
                  <p className="fade-in">{queSomos.Par_Descripcion}</p>
                  <h2 className="fade-in">Desarrolladores</h2>
                  <p className="fade-in">Este sitio fue desarrollado por estudiantes de la Universidad Nacional de Costa Rica. Los estudiantes que participaron en el desarrollo del sitio son:</p>
                  <ul className="fade-in">
                      <li>Dilan Sancho López - Correo: dio3sancho@gmail.com - FullStack Developer</li>
                      <li>Joshua Granados Loría - Correo: joshua.granados.loria@gmail.com</li>
                      <li>Kevin Mora Valverde - Correo: kevinamv14@gmail.com</li>
                  </ul>
              </div>
          </section>
          <Footer parametros={parametros} />
      </div>
  );
};

export default AcercaDe;

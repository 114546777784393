import React, { useEffect } from "react";
import TableUser from "../Componentes/TableUser/TableUser";
import Navbar from '../Componentes/Navbar/Navbar';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

const MantenimientoPersonaPage = () => {

    const navegar = useNavigate();
    const cookies = new Cookies();
  
    useEffect(() => {
      if(!cookies.get('token')){
        navegar("/");
      }
    }, []);
     
    return (
        <div>
           <Navbar></Navbar>
            <h1 className='titulo'>Mantenimiento Personas</h1>
            <TableUser/>         
        </div>
    );

}

export default MantenimientoPersonaPage;
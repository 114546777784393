import Carousel from 'react-bootstrap/Carousel';
import Logo from '../../Recursos/Logo-DEFINITIVO.png';
import CIDEP from '../../Recursos/Logo_CIDEPrv.png';
import Campus from '../../Recursos/campusPZ_1574_600.jpg';
import './Carusel.css'


const Carusel = () => {


  return (
    <Carousel>

      <Carousel.Item interval={1000}>
        <img src={Logo} alt='logo' className='carousel-image'/>
        <Carousel.Caption>
        <div className='carousel-div'>
          <h3 >Centro de Escritura</h3>
          <p >Universidad Nacional Sede Region Brunca</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={500}>
      <img src={CIDEP} alt='CIDEP' className='carousel-image'/>
        <Carousel.Caption>
          <div className='carousel-div'>
          <h3 >Centro de Escritura</h3>
          <p >Universidad Nacional Sede Region Brunca</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={Campus} alt='Campus' className='carousel-image'/>
        <Carousel.Caption>
        <div className='carousel-div'>
          <h3 >Centro de Escritura</h3>
          <p >Universidad Nacional Sede Region Brunca</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


    </Carousel>
  );
}

export default Carusel;
import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const guardarDocumento = async (datos) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/documents/upload', datos, {
            headers: {
                Authorization: cookies.get('token')
            }
        });
        return response.data;
        
    } catch (error) {
        console.error(error);
    }
}

export const obtenerDocumentos = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/documents/list');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const descargarDocumento = async (file) => {
    try {
        const response = await axios.get(`https://www.srb-unainvestigacion.org:3000/documents/download/${file}`);
        return response.data;
        
    } catch (error) {
        console.error(error);
    }
}

export const obtenerFotos = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/documents/list');
        return response.data;
        
    } catch (error) {
        console.error(error);
    }
}
import React, { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { es } from 'date-fns/locale';
import { format, addDays } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { GetNoDisponibilidadById, GetDisponibilidadById, PostAgendarTutoria } from '../../Service/TutoriasService';
import { EnviarCorreo } from '../../Service/GeneralService';
import Swal from 'sweetalert2';
import './Calendar.css'; // Importa un archivo CSS específico para Calendar

const Calendar = ({ idTutoria, idTutor, isOpen, onClose, tutoria, correoTutor, duracion }) => {
  const [selectedDay, setSelectedDay] = useState(Date.now());
  const [disabledDays, setDisabledDays] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [buttonData, setButtonData] = useState([]);
  const [reservedHours, setReservedHours] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const noDisponibilidad = await GetNoDisponibilidadById(idTutor);
        const blockedDays = noDisponibilidad.diasFaltantes;
        const currentDate = new Date();
        const actualMonday = addDays(currentDate, (1 - currentDate.getDay()) % 7);
        const daysToSubstract = 365;
        const oneYearAgoDate = subtractDays(currentDate, daysToSubstract);
        const blockedDates = [];

        for (let i = 0; i < 365; i++) {
          const currentBlockedDate = addDays(oneYearAgoDate, i);
          blockedDates.push(currentBlockedDate);
        }

        for (let i = 0; i < 365; i++) {
          const currentBlockedDate = addDays(actualMonday, i);
          const currentDayOfWeek = currentBlockedDate.getDay() === 0 ? 7 : currentBlockedDate.getDay();
          if (blockedDays.includes(currentDayOfWeek)) {
            blockedDates.push(currentBlockedDate);
          }
        }

        setDisabledDays(blockedDates);
      } catch (error) {
        console.error("Error al obtener la disponibilidad: ", error);
      }
    };
    fetchData();
  }, [idTutor]);

  function subtractDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  const footer = selectedDay ? (
    <p>{format(selectedDay, 'PPP')}.</p>
  ) : (
    <p>Por favor seleccione una fecha.</p>
  );

  const handleDayClick = async (day) => {
    const currentDayOfWeek = day.getDay() === 0 ? 7 : day.getDay();
    const currentDay = format(day, 'yyyy-MM-dd');
    const disponibilidad = await GetDisponibilidadById(idTutor, currentDayOfWeek, currentDay);
    setButtonData([disponibilidad[0]] || []);
    const reservedHoursArray = disponibilidad.map(item => item.Age_Hora).filter(Boolean);
    setReservedHours(reservedHoursArray);
    setShowButton(true);
  };

  const isHourReserved = (hour) => {
    return reservedHours.includes(hour);
  };

  const handleButtonClick = (hour) => {
    setSelectedButton(hour);
    setShowInfo(true);
    setName('');
    setEmail('');
  };

  const buttonList = () => {
    if (!selectedDay) return null;

    const buttons = [];

    buttonData.forEach(({ Dis_HoraInicio, Dis_HoraFin }) => {
      if (Dis_HoraInicio && Dis_HoraFin) {
        const startHour = new Date(`1970-01-01T${Dis_HoraInicio}`);
        const endHour = new Date(`1970-01-01T${Dis_HoraFin}`);

        while (startHour < endHour) {
          const currentHour = startHour.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });

          if (!isHourReserved(currentHour)) {
            const isSelected = selectedButton === currentHour;
            buttons.push(
              <div
                key={currentHour}
                className={`hour-card ${isSelected ? 'selected' : ''}`}
                onClick={() => handleButtonClick(currentHour)}
              >
                {currentHour}
              </div>
            );
          }
          startHour.setMinutes(startHour.getMinutes() + duracion);
        }
      }
    });

    reservedHours.forEach(reservedHour => {
      const index = buttons.findIndex(button => button.key === reservedHour);
      if (index !== -1) {
        buttons[index] = React.cloneElement(buttons[index], { style: { display: 'none' } });
      }
    });

    return buttons;
  };

  const saveData = async () => {
    const dia = format(selectedDay, 'yyyy-MM-dd');
    const hora = selectedButton;
    const response = await PostAgendarTutoria(dia, hora, idTutoria, name, email, tutoria);
    if (response) {
      const emailContentTutor = `
       Reservación de Tutoría
  
        Buen día, Tutor,
        Se ha agendado una tutoría para el 
        ${dia} a las ${hora}.
  
        Información del solicitante:
        Nombre: ${name}
        Correo: ${email}
  
        Por favor, comuníquese con el solicitante para 
        confirmar la tutoría.
  
        Saludos cordiales,
        Equipo de Tutorías
      `;

      const emailContentSolicitante = `
        **Confirmación de Tutoria**
  
        Buen día, ${name},
  
        Has solicitado una tutoría para el 
        ${dia} a las ${hora}.
  
        El tutor se comunicará contigo pronto. 
        Asegúrate de revisar tu correo.
  
        Saludos cordiales,
        Equipo de Tutorías
      `;

      const dataTutor = {
        to: correoTutor,
        subject: 'Reservación de tutoría',
        text: emailContentTutor
      };
      await EnviarCorreo(dataTutor);

      const dataSolicitante = {
        to: email,
        subject: 'Confirmación de agenda de tutoría',
        text: emailContentSolicitante
      };
      await EnviarCorreo(dataSolicitante);

      Swal.fire({
        title: 'Tutoría agendada',
        text: 'Se ha agendado la tutoría exitosamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } else {
      console.error("Error al guardar la tutoría");
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al agendar la tutoría',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const dialogFooter = (
    <div className="dialog-footer">
      <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={onClose} />
      {selectedButton && (
        <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={() => { saveData(); onClose(); }} />
      )}
    </div>
  );

  return (
    <Dialog
      className="p-fluid"
      header="Agendar Tutoría"
      visible={isOpen}
      onHide={onClose}
      footer={dialogFooter}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50rem' }}
    >
      <div className="calendar-container">
        <div className="day-picker-container">
          <DayPicker
            locale={es}
            mode="single"
            selected={selectedDay}
            onSelect={setSelectedDay}
            onDayClick={(day, { selected }) => {
              setSelectedDay(selected ? undefined : day);
              handleDayClick(day);
            }}
            disabled={disabledDays}
            captionLayout="dropdown"
            fromYear={1990}
            toYear={2100}
            footer={footer}
          />
        </div>
  
        <div className="hours-and-info-container">
          <div className="hours-container">
            {selectedDay ? (
              showButton && buttonList()
            ) : (
              <p>Seleccione una fecha para ver las horas disponibles</p>
            )}
          </div>
  
          <div className="info-container">
            <label htmlFor="name">Nombre Completo:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={selectedDay ? name : ''}
              onChange={(e) => setName(e.target.value)}
              disabled={!selectedDay}
              placeholder="Ingrese su nombre"
            />
  
            <label htmlFor="email">Correo:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={selectedDay ? email : ''}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!selectedDay}
              placeholder="Ingrese su correo"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
  
  
}
export default Calendar;

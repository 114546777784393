import React from 'react';
import { useNavigate } from "react-router-dom";
import "./Menu.css";
import taller from "../../Recursos/icono_taller.png";
import foto from "../../Recursos/icono_foto.png";
import tutoria from "../../Recursos/icono_tutoria.png";
import persona from "../../Recursos/icono_persona.png";
import roles from "../../Recursos/icono_roles.png";
import parametros from "../../Recursos/icono_parametros.png";
import tutor from "../../Recursos/icono_tutor.png";
import video from "../../Recursos/icono_video.png";
import documento from "../../Recursos/icono_documento.png";

const Menu = () => {
    const navigate = useNavigate();

    const opcionesMenu = [
        {
            nombre: "Talleres",
            imagen: taller,
            color: "linear-gradient(135deg, #AA98A9, #D3CCE3)",
            onClick: () => navigate('/MantenimientoTallerPage'),
        },
        {
            nombre: "Tutorias",
            imagen: tutoria,
            color: "linear-gradient(135deg, #F9A825, #FFD54F)",
            onClick: () => navigate("/MantenimientoTutoriasPage"),
        },
        {
            nombre: "Personas",
            imagen: persona,
            color: "linear-gradient(135deg, #00EBF7, #00BCD4)",
            onClick: () => navigate("/MantenimientoPersonaPage"),
        },
        {
            nombre: "Roles",
            imagen: roles,
            color: "linear-gradient(135deg, #F1948A, #F8BBD0)",
            onClick: () => navigate("/MantenimientoRolPage"),
        },
        {
            nombre: "Parametros",
            imagen: parametros,
            color: "linear-gradient(135deg, #42DC98, #66BB6A)",
            onClick: () => navigate("/MantenimientoParametroPage"),
        },
        {
            nombre: "Tutores",
            imagen: tutor,
            color: "linear-gradient(135deg, #E262E8, #BA68C8)",
            onClick: () => navigate("/MantenimientoTutorPage"),
        },
        {
            nombre: "Videos",
            imagen: video,
            color: "linear-gradient(135deg, #E8D462, #FFEB3B)",
            onClick: () => navigate("/MantenimientoVideoPage"),
        },
        {
            nombre: "Documentos",
            imagen: documento,
            color: "linear-gradient(135deg, #62B5E8, #42A5F5)",
            onClick: () => navigate("/MantenimientoDocumentoPage"),
        },
        {
            nombre: "Fotos",
            imagen: foto,
            color: "linear-gradient(135deg, #62B5B9, #4DB6AC)",
            onClick: () => navigate("/MantenimientoFotoPage"),
        }
    ];

    return (
        <div className="menu-container">
            <h1 className="menu-title">Menú Principal</h1>
            <div className="menu-page">
                {opcionesMenu.map((opcion, index) => (
                    <div className="menu-card" key={index} style={{ background: opcion.color }} onClick={opcion.onClick}>
                        <img src={opcion.imagen} alt={opcion.nombre} />
                        <h2>{opcion.nombre}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Menu;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import { Navigate } from "react-router-dom";
import './index.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';
import { AuthProvider } from "./Componentes/AuthContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <AuthProvider>
    <App />
    </AuthProvider>
  </React.StrictMode>
);


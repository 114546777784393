import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Modal from '../Modal/Modal';
import { editarDatos } from '../../Service/GeneralService';

import './Table.css';

const Table = ({ prefijo, tabla, columns, initialData }) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const onPageChange = event => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onGlobalFilterChange = event => {
    setGlobalFilter(event.target.value);
  };

  const handleEdit = (rowData) => {
    setSelectedItem(rowData);
    setIsCreating(false);
    setIsModalOpen(true);
  };

  const handleEditUpdate = (updatedData) => {
    setData((prevData) => {
      const newData = [...prevData];
      const dataIndex = newData.findIndex(item => item[`${prefijo}_Id`] === updatedData[`${prefijo}_Id`]);
      if (dataIndex !== -1) {
        newData[dataIndex] = updatedData;
      }
      return newData;
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNew = () => {
    setSelectedItem(null);
    setIsCreating(true);
    setIsModalOpen(true);
  };

  const handleAdd = (newData) => {
    setData([...data, newData]);
  };

  const handleDelete = (rowData) => {
    rowData[`${prefijo}_Estado`] = 'I';

    Swal.fire({
      title: "Esta seguro que desea eliminar este elemento?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!"
    }).then((result) => {
      if (result.isConfirmed) {
        editarDatos(prefijo, tabla, rowData, rowData[`${prefijo}_Id`]);
        const updatedData = data.filter((item) => item[`${prefijo}_Id`] !== rowData[`${prefijo}_Id`]);
        setData(updatedData);
        Swal.fire({
          title: "Eliminado!",
          text: "Elemento eliminado con exito.",
          icon: "success"
        });
      }
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="table-edit-btn" onClick={() => handleEdit(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" className='table-delete-btn' onClick={() => handleDelete(rowData)} />
      </React.Fragment>
    );
  };

  const filteredColumns = columns
    .filter((column) => column.field !== `${prefijo}_Id`)
    .map((column) => ({
      ...column,
      header: column.field.replace(`${prefijo}_`, ''), // Elimina el prefijo
    }));

  const filterHeader = (
    <div className="table-filter">
      <InputText
        type="search"
        onInput={onGlobalFilterChange}
        placeholder="Buscar"
      />
      <Button label="Nuevo" icon="pi pi-plus" className="new-button" severity="success" onClick={handleNew} />
    </div>
  );

  return (
    <div className="Component-datatable">
      <DataTable
        value={data}
        first={first}
        rows={rows}
        totalRecords={data.length}
        showGridlines
        stripedRows
        paginator
        globalFilter={globalFilter}
        header={filterHeader}
        emptyMessage="No hay registros"
        style={{ width: '90%', margin: '0 auto', borderRadius: '10px', overflow: 'hidden' }}
      >
        {filteredColumns.map(column => (
          <Column sortable key={column.field} field={column.field} header={column.header} />
        ))}
        <Column 
          header="Acciones"  // Añade el nombre de la columna
          body={actionBodyTemplate} 
          exportable={false} 
          style={{ minWidth: '10rem' }} 
        />
      </DataTable>
      {isModalOpen && (
        <Modal
          prefijo={prefijo}
          tabla={tabla}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          fields={columns.map(col => ({ name: col.field, label: col.header, type: 'text', value: isCreating ? '' : selectedItem[col.field] }))}
          onAdd={handleAdd}
          onEdit={handleEditUpdate}
        />
      )}
    </div>
  );
};

export default Table;

import React from "react";
import "./VideoCard.css";

const VideoCard = ({ title, description, onClick, thumbnail }) => {
    return (
        <div className="video-card">
            <img
                src={thumbnail}
                alt={title}
                className="video-card-thumbnail"
                onClick={onClick}
            />
            <div className="video-card-content">
                <h3 className="video-card-title">{title}</h3>
                <p className="video-card-description">{description}</p>
                <button className="video-card-button" onClick={onClick}>Ver</button>
            </div>
        </div>
    );
};

export default VideoCard;

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import Navbar from '../Componentes/Navbar/Navbar';  
import { getVideos, saveVideo, updateVideo, deleteVideo } from '../Service/VideoService';


const MantenimientoVideoPage = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const videos = await getVideos();
            setData(videos);
        };
        fetchData();
    }, []);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const onGlobalFilterChange = (event) => {
        setGlobalFilter(event.target.value);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleNew = () => {
        setSelectedItem(null);
        setIsCreating(true);
        setIsModalOpen(true);
    };

    const handleAdd = (newData) => {
        setData([...data, newData]);
    };

    const handleSubmit = () => {
        if (selectedItem.Vid_Id) {
            updateVideo(selectedItem).then(() => {
                handleEditUpdate(selectedItem);
            });
        } else {
            saveVideo(selectedItem).then(() => {
                handleAdd(selectedItem);
            });
        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Video guardado exitosamente",
            showConfirmButton: false,
            timer: 1500,
        });
        handleCloseModal();
    };

    const handleEdit = (rowData) => {
        setSelectedItem(rowData);
        setIsCreating(false);
        setIsModalOpen(true);
    };

    const handleEditUpdate = (updatedData) => {
        setData((prevData) => {
            const newData = [...prevData];
            const dataIndex = newData.findIndex((item) => item.Vid_Id === updatedData.Vid_Id);
            if (dataIndex !== -1) {
                newData[dataIndex] = updatedData;
            }
            return newData;
        });
    };

    const handleDelete = (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, bórralo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteVideo(rowData.Vid_Id);
                    const updatedData = data.filter((item) => item.Vid_Id !== rowData.Vid_Id);
                    setData(updatedData);
                    Swal.fire('Eliminado!', 'El video ha sido eliminado.', 'success');
                } catch (error) {
                    console.error(error);
                }
            }
        });
    };

    const filterHeader = (
        <div className="table-filter">
            <InputText
                type="search"
                onInput={onGlobalFilterChange}
                placeholder="Buscar"
            />
            <Button
                label="Nuevo"
                icon="pi pi-plus"
                className="button-new p-button-success"
                onClick={handleNew}
            />
        </div>
    );

    const dialogFooter = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={handleSubmit} />
        </div>
    );

    return (
     <div><Navbar></Navbar>
      <h1 className='titulo'>Mantenimiento Taller</h1>
        <div className="Component-datatable ">
          
            <DataTable
                value={data}
                paginator
                rows={rows}
                first={first}
                onPage={onPageChange}
                totalRecords={data.length}
                globalFilter={globalFilter}
                header={filterHeader}
                emptyMessage="No hay registros"
            >
                <Column field="Vid_Nombre" header="Nombre" sortable></Column>
                <Column field="Vid_Descripcion" header="Descripción" sortable></Column>
                <Column
                 header="Acciones"
                   body={(rowData) => (
                    <>
                   <Button
                       icon="pi pi-pencil"
                       class="p-button-rounded p-button-edit p-mr-2 p-button p-component p-button-icon-only"
                       onClick={() => handleEdit(rowData)}
                   />
                 <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-warning"
                onClick={() => handleDelete(rowData)}
            />
        </>
    )}
></Column>

            </DataTable>

            <Dialog
                header={isCreating ? 'Crear nuevo video' : 'Editar video'}
                visible={isModalOpen}
                onHide={handleCloseModal}
                footer={dialogFooter}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="Vid_Nombre">Nombre</label>
                        <InputText
                            id="Vid_Nombre"
                            type="text"
                            value={selectedItem?.Vid_Nombre || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Vid_Nombre: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Vid_Descripcion">Descripción</label>
                        <InputTextarea
                            id="Vid_Descripcion"
                            value={selectedItem?.Vid_Descripcion || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Vid_Descripcion: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Vid_Url">URL</label>
                        <InputText
                            id="Vid_Url"
                            type="text"
                            value={selectedItem?.Vid_Url || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Vid_Url: e.target.value })}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
        </div>
    );
};

export default MantenimientoVideoPage;

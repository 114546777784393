import axios from "axios";

// Obtener todos los videos
export const getVideos = async () => {
    try {
        const response = await axios.get("https://www.srb-unainvestigacion.org:3000/videos/GetAllVideos");
        return response.data;
    } catch (error) {
        console.error("Error al obtener los videos", error);
    }
}

// Guardar un nuevo video
export const saveVideo = async (videoData) => {
    try {
        const response = await axios.post("https://www.srb-unainvestigacion.org:3000/videos/SaveVideo", videoData);
        return response.data;
    } catch (error) {
        console.error("Error al guardar el video", error);
    }
}

// Actualizar un video existente
export const updateVideo = async (videoData) => {
    try {
        const response = await axios.put("https://www.srb-unainvestigacion.org:3000/videos/UpdateVideo", videoData);
        return response.data;
    } catch (error) {
        console.error("Error al actualizar el video", error);
    }
}

// Eliminar un video
export const deleteVideo = async (videoId) => {
    try {
        const response = await axios.delete(`https://www.srb-unainvestigacion.org:3000/videos/DeleteVideo/${videoId}`);
        return response.data;
    } catch (error) {
        console.error("Error al eliminar el video", error);
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import './Card.css';

const Card = ({ nombre, descripcion, tutor, imagen, color, onClick }) => {
  return (
    <div className="taller-card">
      <div className="taller-header">
        <h2 className="taller-nombre">{nombre}</h2>
      </div>
      {imagen && <img src={imagen} alt={nombre} className="taller-image" />}
      <p className="taller-descripcion">{descripcion}</p>
      {tutor && (
        <p className="taller-tutor">
          <span className="tutor-label">Tutor:</span> {tutor}
        </p>
      )}
      <button className="solicitar-boton" onClick={onClick}>Solicitar</button>
    </div>
  );
};

Card.propTypes = {
  nombre: PropTypes.string,
  descripcion: PropTypes.string,
  tutor: PropTypes.string,
  imagen: PropTypes.string, 
  color: PropTypes.string, 
  onClick: PropTypes.func, 
};

export default Card;

import axios from 'axios';

export const getParametros = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/parametros/GetParametros');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}



import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerDocumentos } from '../../Service/DocumentsService';
import './DocumentTableStyle.css';

const DriveDocumentTable = ({ folderId, apiKey }) => {
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await obtenerDocumentos();
        setDocumentList(response);
      } catch (error) {
        console.error("Error al obtener documentos:", error);
      }
    };
    fetchDocuments();
  }, []);

  if (documentList.length === 0) {
    return <div className="loading">Cargando...</div>;
  }

  const handleDownload = (documentId) => {
    window.location.href = `https://www.srb-unainvestigacion.org:3000/documents/download/${documentId}`;
  };

  const renderPreview = (rowData) => {
    return (
      <div className="preview-container">
        <div className="preview-icon">
          <i className="pi pi-file" style={{ fontSize: '2rem', color: '#007bff' }}></i>
        </div>
        <div className="preview-info">
          <h3 className="preview-title">{rowData}</h3>
        </div>
      </div>
    );
  };

  return (
    <div className="document-table-container">
      <h1 className="table-title">Documentos Disponibles</h1>
      <DataTable
        value={documentList}
        stripedRows
        paginator
        rows={5}
        showGridlines
        emptyMessage="No hay documentos disponibles"
        className="document-table"
      >
        <Column
          header="Documentos"
          body={renderPreview}
          headerStyle={{ width: '60%' }}
        />
        <Column
          header="Acciones"
          body={(rowData) => (
            <button className="download-btn" onClick={() => handleDownload(rowData)}>
              Descargar
            </button>
          )}
          headerStyle={{ width: '20%' }}
          style={{ textAlign: 'center' }}
        />
      </DataTable>
    </div>
  );
};

export default DriveDocumentTable;

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { getTutores, postDisponibilidad, putDisponibilidad, deleteDisponibilidad } from '../../Service/TutoresService';

const TableTutor = () => {
    const [first, setFirst] = React.useState(0);
    const [rows, setRows] = React.useState(5);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [data, setData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [disableDropdown, setDisableDropdown] = useState(false);
    const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const tutorias = await getTutores();
            setData(tutorias);
        };
        fetchData();
    }, []);

    const onPageChange = event => {
        setFirst(event.first);
        setRows(event.rows);
    };
    
    const onGlobalFilterChange = event => {
        setGlobalFilter(event.target.value);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setDisableDropdown(false);
    };

    const handleNew = (rowData) => {
        setSelectedItem(rowData);
        setIsCreating(true);
        setIsModalOpen(true);
    };

    const handleSubmit = () => {
        if (isEditing) {
            putDisponibilidad(selectedItem).then(() => {
                setIsEditing(false);
                setDisableDropdown(false);
            });
        } else {
            postDisponibilidad(selectedItem).then(() => {
                setIsCreating(false);
            });
        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Elemento guardado exitosamente",
            showConfirmButton: false,
            timer: 1500,
            target: document.getElementById('root')
        });
        handleCloseModal();
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const handleEdit = (rowData) => {
        rowData.Dis_Estado = 'A';
        setSelectedItem(rowData);
        setIsEditing(true);
        setIsCreating(false);
        setIsModalOpen(true);
        setDisableDropdown(true);
    };

    const handleDeleteAvailability = async (index) => {
        setIsAvailabilityModalOpen(false); // Cerrar el modal antes de mostrar la alerta

        setTimeout(() => {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, bórralo!',
                target: document.getElementById('root'),
                customClass: {
                    popup: 'swal2-z-index'
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updatedAvailability = JSON.parse(selectedItem.Disponibilidad);
                    const disponibilidadToDelete = updatedAvailability[index]; // Obtener el item a eliminar

                    try {
                        await deleteDisponibilidad(selectedItem.id, disponibilidadToDelete.Dia);
                        updatedAvailability.splice(index, 1); // Remover la disponibilidad del estado local
                        setSelectedItem({ ...selectedItem, Disponibilidad: JSON.stringify(updatedAvailability) });

                        Swal.fire(
                            'Eliminado!',
                            'La disponibilidad ha sido eliminada.',
                            'success',
                            { target: document.getElementById('root') }
                        );

                        setTimeout(() => {
                            window.location.reload(); // Refrescar la página
                        }, 1500);
                    } catch (error) {
                        console.error(error);
                        Swal.fire(
                            'Error!',
                            'Hubo un problema eliminando la disponibilidad.',
                            'error',
                            { target: document.getElementById('root') }
                        );
                    }
                }
            });
        }, 300); // Tiempo para cerrar el modal antes de mostrar la alerta
    };

    // Método para mostrar la disponibilidad del tutor sin hacer una llamada adicional al backend
    const handleShowAvailability = (rowData) => {
        setSelectedItem(rowData); // Usamos los datos ya cargados en la tabla
        setIsAvailabilityModalOpen(true);
    };

    const allowExpansion = (rowData) => {
        return rowData.Disponibilidad.length >= 0;
    };

    const obtenerNombreDia = (numeroDia) => {
        const diasSemana = ["", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
        return diasSemana[numeroDia] || "";
    };

    const rowExpansionTemplate = (data) => {
        const disponibilidadData = JSON.parse(data.Disponibilidad);
        return (
            <div className="p-3">
                <h6>Disponibilidad del tutor: {data.Tutor}</h6>
                <DataTable value={disponibilidadData}>
                    <Column field="Dia" header="Dia" body={(rowData) => obtenerNombreDia(rowData.Dia)} sortable></Column>
                    <Column field="HoraInicio" header="Hora de Inicio" sortable></Column>
                    <Column field="HoraFin" header="Hora de Finalización" sortable></Column>
                    <Column
                        header="Acciones"
                        body={(rowData, { rowIndex }) => (
                            <>
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-warning"
                                    onClick={() => handleDeleteAvailability(rowIndex)}
                                />
                            </>
                        )}
                    ></Column>
                </DataTable>
            </div>
        );
    };

    const filterHeader = (
        <div className="table-filter">
            <InputText
                type="search"
                onInput={onGlobalFilterChange}
                placeholder="Buscar"
            />
        </div>
    );

    const dialogFooter = (
        <div>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} style={{marginRight: '10px', borderRadius: '12px'}} />
          <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={handleSubmit}  style={{borderRadius: '12px', width: '130px'}}/>
        </div>
    );

    return (
        <div className="Component-datatable">
            <DataTable
                value={data}
                paginator
                rows={rows}
                first={first}
                onPage={onPageChange}
                totalRecords={data.length}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                className="p-datatable-gridlines"
                globalFilter={globalFilter}
                header={filterHeader}
                emptyMessage="No hay registros"
                style={{ width: '90%', margin: '0 auto', borderRadius: '10px', overflow: 'hidden'}}>
                <Column 
                    expander={allowExpansion} 
                    style={{ width: '5rem' }}/>
                    
                <Column field="Identificacion" header="Identificacion" sortable></Column>
                <Column field="Tutor" header="Tutor" sortable></Column>
                <Column
                    header="Agregar Disponibilidad"
                    body={
                        (rowData) => (
                            <Button
                                label="Agregar"
                                icon="pi pi-plus"
                                className="p-button-rounded p-button-success"
                                onClick={() => {
                                    handleNew(rowData);
                                }}
                            />
                        )
                    }
                ></Column>
                <Column
                    header="Mostrar Disponibilidad"
                    body={
                        (rowData) => (
                            <Button
                                label="Ver"
                                icon="pi pi-eye"
                                className="p-button-rounded p-button-info"
                                onClick={() => handleShowAvailability(rowData)}
                            />
                        )
                    }
                ></Column>
            </DataTable>

            <Dialog
                header="Disponibilidad del Tutor"
                visible={isAvailabilityModalOpen}
                style={{ width: '50%' }}
                onHide={() => setIsAvailabilityModalOpen(false)}
            >
                <DataTable value={JSON.parse(selectedItem?.Disponibilidad || '[]')}>
                    <Column field="Dia" header="Dia" body={(rowData) => obtenerNombreDia(rowData.Dia)} sortable></Column>
                    <Column field="HoraInicio" header="Hora de Inicio" sortable></Column>
                    <Column field="HoraFin" header="Hora de Finalización" sortable></Column>
                    <Column
                        header="Eliminar"
                        body={(_, { rowIndex }) => (
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-danger"
                                onClick={() => handleDeleteAvailability(rowIndex)}
                            />
                        )}
                    ></Column>
                </DataTable>
            </Dialog>

            <Dialog
                header={isCreating ? 'Crear nuevo registro' : 'Editar registro'}
                visible={isModalOpen}
                style={{ width: '90%' }}
                onHide={handleCloseModal}
                footer={dialogFooter}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="Tutor">Tutor</label>
                        <InputText
                            id="Tutor"
                            type="text"
                            readOnly
                            value={selectedItem?.Tutor || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Tutor: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Dia">Dia</label>
                        <Dropdown
                            id="Dia"
                            value={{ label: obtenerNombreDia(selectedItem?.Dia), value: selectedItem?.Dia }}
                            options={[
                                { label: 'Lunes', value: 1 },
                                { label: 'Martes', value: 2 },
                                { label: 'Miércoles', value: 3 },
                                { label: 'Jueves', value: 4 },
                                { label: 'Viernes', value: 5 },
                                { label: 'Sábado', value: 6 },
                                { label: 'Domingo', value: 7 }
                            ]}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Dia: e.value.value })}
                            optionLabel='label'
                            placeholder="Seleccione un dia"
                            disabled={disableDropdown}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="HoraInicio">Hora de Inicio</label>
                        <InputText
                            id="HoraInicio"
                            type="time"
                            value={selectedItem?.HoraInicio || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, HoraInicio: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="HoraFin">Hora de Finalización</label>
                        <InputText
                            id="HoraFin"
                            type="time"
                            value={selectedItem?.HoraFin || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, HoraFin: e.target.value })}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default TableTutor;

import DocumentsTable from '../Componentes/DocumentsTable/DocumentsTable';
import Navbar from '../Componentes/Navbar/Navbar';
import Footer from "../Componentes/Footer/Footer";

const DocumentPage = () => {
  const folderId = '1davGlrcI8GKVLq6dC3DdO1jjvFZYGU5C';
  const apiKey = 'AIzaSyBz8wiL1tPZwmWIg3LtWEeynIt0VT9NRvI';

  return (
    <div>
      <Navbar></Navbar>
      <h1 className='titulo'>Documentos</h1>
      <DocumentsTable folderId={folderId} apiKey={apiKey} />
      
    </div>
  );
};

export default DocumentPage;


import React from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import ChangePassword from '../Componentes/ChangePassword/ChangePassword';

const ChangePasswordPage = () => {
  
  return (
    <div>
      <Navbar></Navbar>
      <ChangePassword />
    </div>
  );
};

export default ChangePasswordPage;

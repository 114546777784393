import React, { useState, useEffect } from "react";
import Table from "../Componentes/Table/Table";
import Navbar from '../Componentes/Navbar/Navbar';
import { getTalleres } from "../Service/TalleresService";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

const MantenimientoTallerPage = () => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const navegar = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        if(!cookies.get('token')){
        navegar("/");
        }
    }, []);

    useEffect(() => {
        getTalleres().then(data => {
            if (data.length === 0) {
               setColumns([]);
                setData([]);
            }else{
                setColumns(Object.keys(data[0]).map(key => ({ field: key, header: key })));
                setData(data);
            }
           
        });

    }
    , []);

    
    return (
        <div>
          <Navbar></Navbar>
            <h1 className='titulo'>Mantenimiento Taller</h1>
           
            <Table prefijo={"Tal"} tabla={"ce_talleres"} columns={columns} initialData ={data}  />
            
        </div>
    );

}

export default MantenimientoTallerPage;
import React, { useState } from 'react';
import Cookies from "universal-cookie";
import { Link, useNavigate } from 'react-router-dom';
import './ChangePassword.css';
import imgLogin from '../../Recursos/estudiante.png';
import Swal from 'sweetalert2';
import { CodigoRecuperacion, CambiarContrasena } from '../../Service/GeneralService';

const ChangePassword = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const [codeVerification, setCodeVerification] = useState('');

    const [code, setCode] = useState('');

    const [password, setPassword] = useState('');

    const [clicked, setClicked] = useState(false);

    const [userId, setUserId] = useState('');

    const solicitarCambio = async () => {

        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor ingrese el correo electrónico',
            })
        } else {
            const data = {
                email
            }
            const response = await CodigoRecuperacion(data);

            if (response) {
                setTimeout(() => {
                    console.log(response[0]);
                    setCodeVerification(response.codigo);
                    setUserId(response.Usu_Id);
                    setClicked(true);
                    setTimeout(() => {
                        setCodeVerification('');
                    }, 300000);

                }, 2000);

                Swal.fire({
                    icon: 'success',
                    title: 'Código enviado',
                    text: 'Se ha enviado un código de verificación a su correo electrónico',
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'El correo electrónico no se encuentra registrado',
                })
            }
        }
    }

    const cambiarContrasena = async () => {
        if (code === '' || password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor complete los campos',
            });
        } else {
            if (codeVerification.toString() === code) {
                const data = {
                    password,
                    userId 
                };
                console.log("Datos enviados para cambiar la contraseña:", data); // Añade este log
                const response = await CambiarContrasena(data);
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Contraseña cambiada',
                        text: 'La contraseña se ha cambiado correctamente',
                    });
                    setClicked(false);
                    setEmail('');
                    setCode('');
                    setPassword('');
                    setCodeVerification('');
                    setUserId('');
                    navigate('/LoginPage');
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No se ha podido cambiar la contraseña',
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'El código de verificación es incorrecto',
                });
            }
        }
    };
   
    const handleClicked = () => {
        if (clicked) {
            cambiarContrasena();
        }else{
            solicitarCambio();
        }
    }


  return (
    <div className="login-page">
      <div className="login-card">
        <div className="login-left">
          <img
            src={imgLogin}
            alt="Imagen"
            className="login-image"
          />
        </div>
        <div className="login-right">
          <div className="login-logo">
            <h2>Cambio de Contraseña</h2>
          </div>
          <form>
            <label htmlFor="email">Ingrese su correo electrónico:</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="ejemplo@est.una.ac.cr" 
            />

            {clicked && (
                <>
                  <label htmlFor="code">Digite el código de verificación:</label>               
                  <input 
                    type="text" 
                    id="code" 
                    name="code" 
                    value={code} 
                    onChange={(e) => setCode(e.target.value)} 
                    placeholder="Código de 6 dígitos" 
                  />
                  <label htmlFor="password">Digite la nueva contraseña:</label>
                  <input 
                    type="password" 
                    id="password" 
                    name="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="Nueva contraseña" 
                  />
                </>
            )}

            <button type='button' className='button_loggin' onClick={handleClicked}>
                {clicked ? 'Guardar Contraseña' : 'Solicitar código de verificación'}
            </button>
          </form>
          
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;

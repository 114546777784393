import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Obtener todos los tutores
export const getTutores = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/tutores/GetAllTutores');
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

// Agregar disponibilidad
export const postDisponibilidad = async (disponibilidad) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/tutores/PostDisponibilidad', disponibilidad, {
            headers: {
                Authorization: cookies.get('token') // Agregar token en la cabecera
            }
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

// Actualizar disponibilidad
export const putDisponibilidad = async (disponibilidad) => {
    try {
        const response = await axios.put('https://www.srb-unainvestigacion.org:3000/tutores/PutDisponibilidad', disponibilidad, {
            headers: {
                Authorization: cookies.get('token') // Agregar token en la cabecera
            }
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

// Obtener disponibilidad por ID del tutor (corregido)
export const getDisponibilidad = async (tutorId) => {
    try {
        // Aquí usamos el parámetro de ruta (tutorId)
        const response = await axios.get(`https://www.srb-unainvestigacion.org:3000/tutores/GetDisponibilidad/${tutorId}`);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

// Eliminar disponibilidad (corregido)
export const deleteDisponibilidad = async (id, dia) => {
    try {
        // Enviar los parámetros directamente en la URL
        const response = await axios.delete(`https://www.srb-unainvestigacion.org:3000/tutores/DeleteDisponibilidad/${id}/${dia}`, {
            headers: {
                Authorization: cookies.get('token') // Agregar token en la cabecera
            }
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

// Obtener todas las disponibilidades (nuevo)
export const getAllDisponibilidades = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/tutores/GetAllDisponibilidades');
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

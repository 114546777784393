import React from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import Login from '../Componentes/Login/Login';

const LoginPage = () => {
  
  return (
    <div>
      <Navbar></Navbar>
    
      <Login />
    </div>
  );
};

export default LoginPage;

import React from 'react';
import VideoRenderer from '../Componentes/Video/VideoRenderer';
import Navbar from '../Componentes/Navbar/Navbar';

const VideoPage = () => {

  return (
    <div>
      <Navbar></Navbar>
      <h1 className='titulo'>Videos</h1>
      <VideoRenderer />
    </div>
  );
};

export default VideoPage;

import axios from 'axios';

export const getTalleres = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/taller/GetTaller');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}




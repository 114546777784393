import React, { useEffect } from "react";
import MaintenanceDocument from "../Componentes/MaintenanceDocument/MaintenanceDocument";
import Navbar from '../Componentes/Navbar/Navbar';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

const MantenimientoDocumentoPage = () => {

    const navegar = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        if(!cookies.get('token')){
        navegar("/");
        }
    }, []);

        
    return (
        <div>
          <Navbar></Navbar>
            <h1 className='titulo'>Mantenimiento de Documentos</h1>
            <MaintenanceDocument/>
            
        </div>
    );

}

export default MantenimientoDocumentoPage;
import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import Swal from 'sweetalert2';
import axios from 'axios';
import { obtenerDocumentos } from '../../Service/DocumentsService';
import Cookies from "universal-cookie";
import './MaintenanceDocument.css';


const MaintenanceDocument = () => {
    const cookies = new Cookies();
    const [documentList, setDocumentList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [file, setFile] = useState(null);

    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        obtenerDocumentos().then(response => {
            if (response && Array.isArray(response)) {
                const documentosNoImagenes = response.filter(doc => {
                    if (doc) {
                        const extension = doc.split('.').pop().toLowerCase();
                        console.log(extension);
                        console.log(response);
                        return !['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
                    }
                    return false;
                });
                setDocumentList(documentosNoImagenes);
            } else {
                console.error("La respuesta de obtenerDocumentos() no es un array válido:", response);
                setDocumentList([]);
              
            }
        });
    }, []);

    if (documentList.length === 0) {
        return <div>Cargando...</div>;
    }


    const handleDelete = (documentId) => {
        
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, bórralo',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://www.srb-unainvestigacion.org:3000/documents/delete/${documentId}`, { headers: {
                    Authorization: cookies.get('token')
                }}).then(response => {
                        obtenerDocumentos().then(response => {
                            setDocumentList(response);
                        });
                    })
                    .catch(error => {
                        console.error('Error eliminando el documento:', error);
                    });
            }
        });

    }

    const handleNew = () => {
       
        setOpenModal(true);
    }

    const header = (
        <div className="flex flex-wrap gap-2">
            <Button
                label="Nuevo"
                icon="pi pi-plus"
                severity="success"
                onClick={handleNew}
            />
        </div>
    );

    const saveDocument = () => {
        const formData = new FormData();
        file.forEach((file) => {
            formData.append('file', file);
        });
        axios.post('https://www.srb-unainvestigacion.org:3000/documents/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: cookies.get('token')
            }
            })
        
            .then(response => {
     
                obtenerDocumentos().then(response => {
             
                    setDocumentList(response);
                });
                setOpenModal(false);
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Elemento guardado exitosamente",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch(error => {
                console.error('Error subiendo el documento:', error);
            });
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }


    const dialogFooter = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} style={{ marginRight: '10px', borderRadius: '12px' }} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={() => saveDocument()} style={{ borderRadius: '12px', width: '130px' }} />
        </div>
    );

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;
        setFile(files);

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    // const onTemplateUpload = (e) => {
    //     let _totalSize = 0;

    //     e.files.forEach((file) => {
    //         _totalSize += file.size || 0;
    //     });

    //     setTotalSize(_totalSize);
    //     toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    // };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        
        const value = totalSize / 1000000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', width: '100%' }}>
                {chooseButton}
              
                {cancelButton}
                <div className="flex align-items-center gap-2 ml-auto">
                    <span>{formatedValue} / 50 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '50%' }}>
                    <i className="pi pi-file p-mr-3" style={{ fontSize: '2em' }}></i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Arrastra y suelta el documento aquí
                </span>
            </div>
        );
    };


    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    return (
        <div className="Component-datatable">
            <Toolbar className="mb-4" left={header} style={{ width: '90%', margin: '0 auto', borderRadius: '10px', overflow: 'hidden' }}></Toolbar>

            <DataTable
                value={documentList}
                stripedRows
                paginator
                rows={5}
                showGridlines
                className="p-datatable-gridlines"
                emptyMessage="No hay documentos disponibles"
                style={{ width: '90%', margin: '0 auto', borderRadius: '10px', overflow: 'hidden' }}

            >
                <Column header="Nombre del Documento" body={rowData => rowData} />

                <Column
                    header="Acciones"
                    body={(rowData) => (
                        <>
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-warning"
                                onClick={() => handleDelete(rowData)}
                            />
                        </>
                    )}
                />

            </DataTable>
            <Dialog header="Nuevo Documento" footer={dialogFooter} visible={openModal} style={{ width: '90%' }} onHide={() => setOpenModal(false)}>
                <div className="flex flex-col gap-4">
                    {/* <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    /> */}
                    {/* <Toast ref={toast}></Toast>

                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
                    <FileUpload
                        mode="basic"
                        name="demo[]"
                        url="https://primefaces.org/primereact/showcase/upload.php"

                        maxFileSize={1000000}
                        onUpload={(e) => setFile(e.files[0])}
                        chooseOptions={chooseOptions}
                        cancelOptions={cancelOptions}
                        customUpload
                    /> */}
                    <Toast ref={toast}></Toast>

                    <Tooltip target=".custom-choose-btn" content="Elegir" position="bottom" />
                    
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                    <FileUpload 
                        ref={fileUploadRef} 
                        name="demo[]" 
                        url="/api/upload" 
                        multiple
                        maxFileSize={50000000}
                        onUpload={(e) => setFile(e.files[0])}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear} 
                        onClear={onTemplateClear}
                        headerTemplate={headerTemplate} 
                        itemTemplate={itemTemplate} 
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}  
                        cancelOptions={cancelOptions}
                        style={{ width: '100%' }} 
                        />
                </div>
            </Dialog>
        </div>
    );

};

export default MaintenanceDocument;




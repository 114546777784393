import React, { useState, useEffect } from "react";
import Table from "../Componentes/Table/Table";
import Navbar from '../Componentes/Navbar/Navbar';
import { getParametros } from "../Service/ParametrosService";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

const MantenimientoParametroPage = () => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const navegar = useNavigate();
    const cookies = new Cookies();

  useEffect(() => {
    if(!cookies.get('token')){
      navegar("/");
    }
  }, []);

    useEffect(() => {
        getParametros().then(data => {
          if (data.length === 0) {
            return <div>Cargando...</div>;
        }else{
            setColumns(Object.keys(data[0]).map(key => ({ field: key, header: key })));
            setData(data);
        }
        });

    }
    , []);
     
    return (
        <div>
           <Navbar></Navbar>
            <h1 className='titulo'>Mantenimiento Parametros</h1>
           
            <Table prefijo={"Par"} tabla={"ce_parametros"} columns={columns} initialData ={data}  />
            
        </div>
    );

}

export default MantenimientoParametroPage;
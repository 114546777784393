import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import Table from "../Componentes/Table/Table";
import Navbar from '../Componentes/Navbar/Navbar';
import { getUsuarios } from "../Service/UsuarioService";


const MantenimientoUsuarioPage = () => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const navegar = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        if(!cookies.get('token')){
        navegar("/");
        }
    }, []);

    useEffect(() => {
        getUsuarios().then(data => {
            if (data.length === 0) {
                return <div>Cargando...</div>;
            }else{
            setColumns(Object.keys(data[0]).map(key => ({ field: key, header: key })));
            setData(data);
            }
        });

    }
    , []);
     
    return (
        <div>
           <Navbar></Navbar>
            <h1 className='titulo'>Mantenimiento Usuarios</h1>
           
            <Table prefijo={"Usu"} tabla={"ce_usuarios"} columns={columns} initialData ={data}  />
            
        </div>
    );

}

export default MantenimientoUsuarioPage;